import styles from "../styles/Footer.module.css";
import Link from "next/link";
import Image from 'next/image'

export default function Footer() {
  return (
    <footer className={styles.footer}>
<div className={styles.footlinks}>
  <ul className={styles.clearfix}>
          <li><Link href="/about"><a title="Cyberlab Australia">Cyberlab</a></Link></li>
          <li><Link href="/security"><a title="Security Solution">Security Solution</a></Link></li>
          <li><Link href="/alerts"><a title="Security Alerts">Security Alerts</a></Link></li>
          <li><Link href="/privacy"><a title="Cyberlab Privacy Policy">Privacy Policy</a></Link></li>
          <li><Link href="/contact"><a title="Cyberlab Contact">Connect with Us</a></Link></li>
        </ul>
</div><p className={styles.copy}>All Rights Reserved. <i>Cyberlab Security Australia</i> © 2024</p> Designed by <Link href="https://d24.me"><a title="D24 Media">D24 Media</a></Link>.
</footer>


  );
}