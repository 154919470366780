
import styles from "../styles/Splash.module.css";
import Link from "next/link";
import Image from 'next/image'

export default function Banner() {
  return (
    <div className={styles.banner}>
<h2 className={styles.txtcenter}>Simplicity and User-Friendly</h2>
          <h3>Our solutions are designed for end-users in mind</h3><p className={styles.bannertxt}>Strike the perfect balance between leading technology security and usability. Solutions are crafted to be easily comprehensible, ensuring accessibility for all stakeholders. Streamline communication which makes cybersecurity insights clear and actionable for everyone.</p>
          <div className={styles.centerbutton}><Link href="/contact"><a title="Cyberlab"><button>Contact us</button></a></Link></div>
        
          </div> 

          
  );
  }