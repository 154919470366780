import styles from "../styles/Splash.module.css";
import Link from "next/link";
import Image from 'next/image'
import { useState, useEffect } from 'react';

export default function Framework() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // You can adjust the threshold based on your design
    };

    // Initial check on mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const imageSrc = isMobile ? "/cyber-security-framework-nist-mobile.svg" : "/cyber-security-framework-nist.svg";
  const imageWidth = isMobile ? 460 : 1600;
  const imageHeight = isMobile ? 830 : 860;

  return (
    <div className={styles.frame}>
      <h2 className={styles.txtcenter}>Globally Adopted Cyber Security Standard</h2>
      <h3>Embrace a structured approach to cybersecurity with Industry-leading principles</h3>
      <p className={styles.frametxt}>Applying framework designed to facilitate organisations in effectively communicating cybersecurity risks to management and providing straightforward mitigation strategies for prevention</p>
      <p className={styles.centerimg}>
        <Image
          src={imageSrc}
          width={imageWidth}
          height={imageHeight}
          alt="cyber security framework"
          title="cyber security framework"
        />
      </p>

      <div className={styles.centerbutton}><Link href="/security"><a title="Cyberlab Security Solution"><button>Our Solutions</button></a></Link></div>
    </div>
  );
}
