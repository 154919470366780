import Head from 'next/head';
import styles from '../styles/Layout.module.css';
import Header from './Header';
import Footer from './Footer';
import Splash from './Splash';
import Banner from './Banner';
import Framework from './Framework';
import { useRouter } from "next/router";
export default function Layouts({ title, description, keywords, canonical, children }) {
  const router = useRouter();
  return (
    <div>      
      <Head>
         <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical}/>
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" /> 
<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
<link rel="manifest" href="/site.webmanifest" />
<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#4e00b4" />
<meta name="msapplication-TileColor" content="#4e00b4" />
<meta name="theme-color" content="#ffffff" />
 <meta name="keywords" content={keywords} />
        <meta name="theme-color" content="#999" />
      </Head>
      <Header />
      {router.pathname === "/" && <Splash />}
      {router.pathname === "/" && <Framework />}
      {router.pathname === "/" && <Banner />}
      <main className={styles.container}>{children}
 
      </main>
      <Footer />
     
    </div>
  );
}

Layouts.defaultProps = {
  title: "Cyberlab Security Australia",
  description: "Cyberlab Security Australia advanced cybersecurity solutions robust threat detection to state-of-the-art vulnerability assessments, our experts deliver top-notch services to safeguard your digital assets",
  keywords: "cyberlab, cybersecurity, Australia",
  canonical: "https://cyberlabau.com",
};  