import Link from "next/link";
import Image from "next/image";
import styles from "../styles/NewsItem.module.css";

export default function NewsItem({ news }) {
  // Helper function to format the date
  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split(' ');
    return (
      <div className={styles.date}>
        <div className={styles.day}>{day}</div>
        <div className={styles.month}>{month.slice(0, 3)}</div>
        <div className={styles.year}>{year}</div>
      </div>
    );
  };

  return (
    <div className={styles.news}>
      <div className={styles.dateContainer}>
        {formatDate(news.date)}
      </div>
      <div className={styles.content}>
        <Link href={`/alerts/${news.slug}`}>
          <a title={news.name}>
            <h3>{news.name}</h3>
            <p>{news.summary}</p>
          </a>
        </Link>
      </div>
    </div>
  );
}