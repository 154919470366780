import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styles from '../styles/Header.module.css';

export default function Header() {
  const [showMe, setShowMe] = useState(false);
  const [isShrunk, setIsShrunk] = useState(false);
  const router = useRouter();

  function toggle() {
    setShowMe(!showMe);
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      // You can adjust the threshold for when the header should start shrinking
      setIsShrunk(scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Check if the current route is the homepage ("/")
  const isHomePage = router.pathname === '/';

  return (
    <header className={styles.header}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>

      {/* Conditionally render the logo based on the current route */}
      {isHomePage ? null : (
        <div className={`${styles.logo} ${isShrunk ? styles.shrunk : ''}`}>
          <Link href="/">
            <a>
              <Image
                itemProp="image"
                src="/Cyberlab-Australia.svg"
                layout="fixed"
                alt="Cyberlab Security Australia"
                width={390}
                height={220}
                priority
                loading="eager"
              />
            </a>
          </Link>
          <div className={styles.cyberlab}>Cyberlab</div>
          <p className={styles.cyberlabsub}>Australia</p>
        </div>
      )}

      <nav className={showMe ? styles.show : styles.hide}>
        <ul className={styles.menu}>
          <li>
            <Link href="/about">
              <a title="Cyberlab Australia">Cyberlab</a>
            </Link>
          </li>
          <li>
            <Link href="/security">
              <a title="Security Solution">Security Solution</a>
            </Link>
          </li>
          <li>
            <Link href="/alerts">
              <a title="Security Alerts">Security Alerts</a>
            </Link>
          </li>
          <li>
            <Link href="/contact">
              <a title="Cyberlab Contact">Connect with Us</a>
            </Link>
          </li>
        </ul>
      </nav>

      <button className={styles.hamb} onClick={toggle} id="cyberlabmenu" title="Cyberlab menu">
        {showMe ? (
          <span className={styles.close}></span>
        ) : (
          <span className={styles.hambline}></span>
        )}
      </button>
    </header>
  );
}
