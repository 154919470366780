
import styles from "../styles/Splash.module.css";
import Link from "next/link";
import Image from 'next/image'

export default function Splash() {
  return (
    <div className={styles.slide}>
<span className={styles.slideimg}>
<Image 
          itemProp="image" 
          src="/Cyberlab-Australia.svg" 
          layout="fixed" 
          alt="Cyberlab Security Australia" 
          width={860} 
          height={560}  
          priority
          loading="eager" 
       
        />
        
        </span>
      <span className={styles.center}><h1>Cyberlab</h1></span> <span className={styles.center} ><h2>Security Australia</h2></span>
      
      <div className={styles.center}><h3>Safeguard Your Digital Realm</h3></div>
        <div className={styles.center}><p>The Imperative of Cybersecurity. Protect your data, privacy, and assets in an interconnected world with robust cybersecurity measures, your shield against evolving online threats. Findout how we can help you </p></div>
        <div className={styles.centerbutton}><Link href="/about"><a title="Cyberlab Australia"><button>About us</button></a></Link><Link href="/security"><a title="Cyberlab Security Solution"><button>Our Solutions</button></a></Link></div>
        
          </div> 

          
  );
  }